import { useContext, useEffect, useState } from "react";
import { icsUseLocation, returnRouterParams } from "./icsUseLocation";
import UserContext from 'context/UserContext'
import { getAdminList, getAdminUserDetail, postUserData, putUserData, putUserPermission } from "services/user";
import { useLocation } from "wouter";

const INITIAL_PAGE = 1

export function arraySearchAddress(addresses, number) {
    return Object.values(addresses).find(
        function (element) {
            const match = element.number == number

            return match
        }
    )
}

export function arraySearchPrimaryAddress(addresses) {
    return arraySearchAddress(addresses, 0)
}

export function useAdminList() {

    const[, setLocation,query] = icsUseLocation();
    const q = returnRouterParams(query);
    const [urlParams, setUrlParams] = useState(q);
    const [prevSearch, setPrevSearch] = useState({username: "", page: 1});
    const [users, setUsers] = useState({pagination: null, items: []})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setUrlParams(returnRouterParams(query));
    }, [query]);

    const [errorMessage, setErrorMessage] = useState(false)
    const { login } = useContext(UserContext)
    const { jwt, userId } = login


    useEffect(function () {

        if (prevSearch.username !== urlParams.username || prevSearch.page !== urlParams.page) {
            setLoading(true)
            getAdminList({jwt, userId, username: urlParams.username, page: urlParams.page}).then(items => {
                setUsers(items)
                setLoading(false)
            }).catch(err => {
                setErrorMessage(err)
            })
        }
        setPrevSearch({...urlParams})
    }, [urlParams, setUsers, userId, jwt])

    return { loading, users, errorMessage }

}

export function useAdminUserDetail(id) {

    const [prevSearch, setPrevSearch] = useState({id: undefined});
    const [user, setUser] = useState()
    const [loading, setLoading] = useState(false)

    const [errorMessage, setErrorMessage] = useState(false)
    const { login } = useContext(UserContext)
    const { jwt, userId } = login


    useEffect(function () {

        if (id !== undefined && prevSearch.id !== id) {
            setLoading(true)
            getAdminUserDetail({jwt, userId, id: id}).then(items => {
                setUser(items)
                setLoading(false)
            }).catch(err => {
                setErrorMessage(err)
            })
        }
        setPrevSearch({...id})
    }, [id, setUser, userId, jwt])

    return { loading, user, errorMessage }

}

export function usePostUserData() {

    const [location, setLocation] = useLocation()
    const [prevSearch, setPrevSearch] = useState()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState()
    const [exit, setExit] = useState(false)

    const [errorMessage, setErrorMessage] = useState(false)
    const { login } = useContext(UserContext)
    const { jwt, userId } = login


    useEffect(function () {
        if (search && (!prevSearch || search !== prevSearch)) {
            setLoading(true)
            postUserData({jwt, userId, data: search}).then(item => {
                setLoading(false)
                if (exit) {
                    setLocation(`/adm-usuarios/`)
                } else {
                    setLocation(`/adm-usuarios/editar/${item.id}?step=2`)
                }
            }).catch(err => {
                setErrorMessage(err)
            })
        }
        setPrevSearch({...{search}})
    }, [search, userId, jwt])

    return { loading, errorMessage, setSearch, setExit }

}

export function usePutUserData(id) {

    const [location, setLocation] = useLocation()
    const [prevSearch, setPrevSearch] = useState({id: undefined, data: undefined});
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState()
    const [exit, setExit] = useState(false)

    const [errorMessage, setErrorMessage] = useState(false)
    const { login } = useContext(UserContext)
    const { jwt, userId } = login


    useEffect(function () {
        if (id !== undefined && search && (prevSearch.id !== id || prevSearch.data !== search)) {
            setLoading(true)
            putUserData({jwt, userId, id: id, data: search}).then(items => {
                setLoading(false)
                if (exit) {
                    setLocation(`/adm-usuarios/`)
                } else {
                    setLocation(`/adm-usuarios/editar/${id}?step=2`)
                }
            }).catch(err => {
                setErrorMessage(err)
            })
        }
        setPrevSearch({...{id, data: search}})
    }, [id, search, userId, jwt])

    return { loading, errorMessage, setSearch, setExit }

}

export function usePutUserPermissions(id) {

    const [prevSearch, setPrevSearch] = useState({id: undefined, permissions: undefined});
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState()

    const [errorMessage, setErrorMessage] = useState(false)
    const { login } = useContext(UserContext)
    const { jwt, userId } = login


    useEffect(function () {
        if (id !== undefined && search && (prevSearch.id !== id || prevSearch.permissions !== search)) {
            setLoading(true)
            putUserPermission({jwt, userId, id: id, permissions: search}).then(items => {
                setLoading(false)
            }).catch(err => {
                setErrorMessage(err)
            })
        }
        setPrevSearch({...{id, permissions: search}})
    }, [id, search, userId, jwt])

    return { loading, errorMessage, setSearch }

}

/*
export default function useUser() {
    const {
        jwt, setJWT,
        userData, setUserData,
        userId, setUserId,
    } = useContext(Context)

    const login = useCallback(({ username, password }) => {
        return loginService({ username, password })
            .then(result => {
                const { jwt, item } = result
                window.sessionStorage.setItem('jwt', jwt)
                window.sessionStorage.setItem('id', item.id)
                setUserData(item)
                setUserId(item.id)
                setJWT(jwt)
                return true
            }).catch(err => {
                window.sessionStorage.removeItem('jwt')
                window.sessionStorage.removeItem('id')
                throw err
            })
    }, [setJWT, setUserData, setUserId])

    const logout = useCallback(() => {
        window.sessionStorage.removeItem('jwt')
        window.sessionStorage.removeItem('id')
        setUserData(null)
        setUserId(null)
        setJWT(null)
    }, [setJWT, setUserData, setUserId])

    const getPermissionValue = useCallback((permission) => {
        const { permissions } = userData

        if (permissions.hasOwnProperty(permission)) {
            return permissions[permission].value
        }
        return null
    }, [userData])

    const isAdmin = useCallback(() => {

        if (userData && userData.idUserCategory === 1) {
            return true
        } else if (userData) {
            return false
        }
        return null
    }, [userData])

    return {
        isLogged: Boolean(jwt) && Boolean(userId),
        isLoad: Boolean(userData),
        getPermissionValue,
        login,
        logout,
        isAdmin,
    }
} */
