import React from 'react';
import ReactDOM from 'react-dom';

import { UserContextProvider } from './context/UserContext'
import { RoutesContextProvider } from './context/RoutesContext'
import { ThemeContextProvider } from 'context/ThemeContext';

import { Provider } from 'react-redux';
import store from './redux'

import App from './App';

import './index.css';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <React.StrictMode>
        <UserContextProvider>
            <RoutesContextProvider>
                <ThemeContextProvider>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </ThemeContextProvider>
            </RoutesContextProvider>
        </UserContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
