import { IS_DEV, IS_LOCAL } from "config/defines";

export const API_KEY = "pruebaApiEnHeaderWeb"
//export const API_URI = "https://nuevaTopApi.desarrollogrupoics.com/v2"
//export const API_URI = "https://local.api.toprecambios.com/v2"
export const API_URI = (!IS_DEV) ? "https://api.toprecambios.com/v2" : ((IS_LOCAL) ? "https://new_top_api.santi.com/v2" : "https://nuevaTopApi.desarrollogrupoics.com/v2")


export default function sendRequest(uri, requestOptions) {
    const headers = requestOptions.headers ?? new Headers();
    headers.append("ICS-API-KEY", API_KEY);
    headers.append("Accept", "*/*");

    if (headers.get("Content-Type") === null) {
        headers.append("Content-Type", "application/json")
    } else if (headers.get("Content-Type") === "unset") {
        headers.delete('Content-Type');
    }

    const requestOptionsGlobal = {
        headers: headers
    };

    requestOptions = Object.assign(requestOptions, requestOptionsGlobal)

    return fetch(API_URI + uri, requestOptions)
        .then(res => {
            return res.json()
        })
        .then(res => {
            //console.log(res)
            if (res.error)
                throw new Error(res.message)
            return res
        })
}


export function getContentLength(formData) {
    const formDataEntries = [...formData.entries()]

    const contentLength = formDataEntries.reduce((acc, [key, value]) => {
        if (typeof value === 'string') return acc + value.length
        if (typeof value === 'object') return acc + value.size

        return acc
    }, 0)

    return contentLength
}
