import React, {useContext} from 'react'
import UserContext from 'context/UserContext'

import './Logout.css'
import { Link } from 'wouter'

export default function Logout() {
    const { login, doLogout } = useContext(UserContext)

    const handleClick = () => {
        doLogout()
    }

    return (
        <Link to="/user">
            <div className="ics-btn-menu-logout ics-btn-logout" title='Usuario'>

                <span className="users-icon"></span>
                <div>
                    Hola, {login.userData.username}
                    <span onClick={handleClick}> Desconexión</span>
                </div>
        </div>
       </Link>
    )
}
