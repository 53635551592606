import styled from '@emotion/styled'

import { Form as FormFormik } from 'formik'

/**
 * Estilos comunes a contenedores de pagina sin registrarse
 */
const commonPagesNoLogin = `
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    display: flex;
    min-height: 80vh;
    width: 100%;
`
/**
 * Estilos de contenedor de pagina de login
 */
export const IcsPageLogin = styled.div`
    ${commonPagesNoLogin}
    min-height: 100vh;
`
/**
 * Estilos del contenedor de pagina de register
 */
export const IcsPageRegistry = styled.div`
    ${commonPagesNoLogin}
    flex-direction: column;
    width: 40%;
    border: 1px solid #333;
    position: absolute;
    left: 0;
    right: 0;
    margin: 25px auto;
    min-height: auto;
    background: rgba(0, 0, 0, 0.6);
`

/**
 * Estilos del contenedor de pagina de register
 */
export const IcsPageGeneralContent = styled.div`
    ${commonPagesNoLogin}
    flex-direction: column;
    margin-top: 0;
    min-height:100%;
    padding: 0 15px;
`

/**
 * Estilos para fomularios
 */
export const Form = styled(FormFormik)`
    display: flex;
    flex-direction: column;
    width: 100%;
`
/**
 * Espacio de top menu
 */
    export const Space = styled.div`
    margin-top:80px;
`

/**
 * Linea de pagina, similar a row de bootstrap
 */
    export const Line = styled.div`
    border-top: 1px solid #f5a700;
    margin: 2em;
`
/**
 * Linea de pagina, similar a row de bootstrap
 */
export const Row = styled.div`
    display: flex;
    padding: 10px 0;
`
/**
 * Columna de una linea similar a col de bootstrap
 */
export const Col1 = styled.div`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    flex-grow:1;
    padding: 0 5px;
`
export const Col2 = styled.div`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    flex-grow:2;
    padding: 0 5px;
`
export const Col3 = styled.div`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    flex-grow:3;
    padding: 0 5px;
`
export const Col4 = styled.div`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    flex-grow:4;
    padding: 0 5px;
`
export const Col5 = styled.div`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    flex-grow:5;
    padding: 0 5px;
`
export const Col6 = styled.div`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    flex-grow:6;
    padding: 0 5px;
`
export const Col7 = styled.div`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    flex-grow:7;
    padding: 0 5px;
`
export const Col8 = styled.div`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    flex-grow:8;
    padding: 0 5px;
`
export const Col9 = styled.div`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    flex-grow:9;
    padding: 0 5px;
`
export const Col10 = styled.div`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    flex-grow:10;
    padding: 0 5px;
`
