import sendRequest from 'services/settings'

export function getUserData({ jwt, userId }) {
    const headers = new Headers();
    headers.append("Authorization", "Bearer " + jwt);

    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    return sendRequest(`/${userId}/user/configuration`, requestOptions)
    .then(result => {
        return result.item
    })
}

export default function login({ username, password }) {
    const raw = JSON.stringify({ username, password });

    const requestOptions = {
        method: 'POST',
        body: raw
    };

    return sendRequest('/login', requestOptions)
}
