
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk'
// import { createLogger } from 'redux-logger'
import tires from './reducers/tires';
import consumables from './reducers/consumables';
import batteries from './reducers/battery';


const middleware = [ thunk ]
if (process.env.NODE_ENV !== 'production') {
  //middleware.push(createLogger())
}

const store = createStore(
  combineReducers({
    tires, consumables, batteries
  }),
  applyMiddleware(...middleware)
)

export default store;
