export const Tires = (state = {
    isLoading: false,
    tires: [],
    errorMessage: ''
}, action) => {
    //console.log("estado del lubricante",state)
    switch(action.type) {
    case 'REQUEST_TIRES':
        state.isLoading = true;
        state.prevSearch = action.search
        return {...state };
    case 'SET_TIRES':
        //console.log(action.tires)
        state.isLoading = false
        state.tires = action.tires
        state.errorMessage = action.errorMessage
        return {...state};
    case 'CHANGE_TIRES':
        state.isLoading = true
        return {...state};
    case 'REMOVE_TIRES':
        state.isLoading = false
        //state.tires = []
        state.errorMessage = ""
        state.quantity = 1
        state.prevSearch = {search: {
            measure: "",
            brokerType: "",
            filters: undefined
        }}

        return {...state};
    default:
        return state;
    }
}

  export default (Tires);
