export const Battery = (state = {
    isLoading: false,
    battery: [],
    errorMessage: ''
}, action) => {
    //console.log(state)
    switch(action.type) {
    case 'REQUEST_BATTERY':
        state.isLoading = true;
        state.prevSearch = action.search
        return {...state};
    case 'SET_BATTERY':
        state.isLoading = false
        state.battery = action.battery
        state.errorMessage = action.errorMessage

        return {...state};
    default:
        return state;
    }
}

export default Battery;
