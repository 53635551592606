import views from "pages"

const routesNoLogin = [
    {
        component : views.Login,
        path : "/login"
    },
    {
        component : views.Registry,
        path : "/registro"
    }
]

export const routesLogin = [
    {
        component : views.Cart,
        path : "/carrito",
        text : "Carrito",
        cssClass : "ics-btn-menu ics-btn-sup cart-icon"
    },
    {
        component : views.Transparency,
        path : "/transparencia",
        text : "Transparencia",
        cssClass : "ics-btn-menu ics-btn-sup transparency-icon"
    },
    {
        component : views.Warranty,
        path : "/garantias",
        text : "Garantías",
        cssClass : "ics-btn-menu ics-btn-sup warranty-icon"
    },
    {
        component : views.Documents,
        path : "/documentos",
        text : "Documentos",
        cssClass : "ics-btn-menu ics-btn-sup document-icon"
    },
    {
        component : views.Info,
        path : "/informacion-etiquetado",
        text : "info",
        cssClass : "ics-btn-menu ics-btn-sup info-icon"
    },
    {
        component : views.SellConditions,
        path : "/condiciones-venta"
    },
    {
        component : views.Devolutions,
        path : "/devoluciones",
        text : "Devoluciones",
        cssClass : "ics-btn-menu ics-btn-sup devolutions-icon"
    },
    {
        component : views.Budget,
        path : "/presupuestos",
        text : "Presupuestos",
        cssClass : "ics-btn-menu ics-btn-sup budget-icon"
    },
    {
        component : views.BudgetEdit,
        path : "/presupuesto/:id",
    },
    {
        component : views.Orders,
        path : "/pedidos",
        text : "Pedidos",
        cssClass : "ics-btn-menu ics-btn-sup invoice-icon"
    },
    {
        component : views.OrdersDetail,
        path : "/pedido/:id"
    },
    {
        component : views.Invoice,
        path : "/facturas",
        text : "Facturas",
        cssClass : "ics-btn-menu ics-btn-sup invoice-icon"
    },
    {
        component : views.UserDetail,
        path : "/user/:id?"
    },
    /* {
        component : views.ProductDetails,
        path : "/consumible/:id",
        text : "Detalles de producto",
    },
    {
        component : views.ProductDetails,
        path : "/cadena/:id",
        text : "Detalles de producto",
    },
    {
        component : views.ProductDetails,
        path : "/lubricante/:id",
        text : "Detalles de producto",
    },
    {
        component : views.ProductDetails,
        path : "/escobilla/:id",
        text : "Detalles de producto",
    },*/
    {
        component : views.NotFound,
        path : "/pagina-no-encuentra"
    },
    {
        component : views.NotFound,
        path : "/:rest*"
    }
]

export const routesAdmin = [
    {
        component : views.AdmSetting,
        path : "/adm-configuracion",
        text : "Configuracion",
    },
    {
        component : views.AdmUsers,
        path : "/adm-usuarios",
        text : "Administracion de Usuarios",
    },
    {
        component : views.AdmUsersCreate,
        path : "/adm-usuarios/nuevo",
    },
    {
        component : views.AdmUsersEdit,
        path : "/adm-usuarios/editar/:id",
    },
    {
        component : views.AdmIps,
        path : "/adm-ips",
        text : "Administracion de IPs",
    },
    {
        component : views.AdmIpsDetail,
        path : "/adm-ips/detalle/:ip"
    },
    {
        component : views.AdmSearch,
        path : "/adm-busquedas",
        text : "Informe de Busquedas ",
    },
    {
        component : views.AdmOrders,
        path : "/adm-pedidos",
        text : "Administracion de pedidos",
    },
    {
        component : views.AdmPortal,
        path : "/adm-portal/:idPortal?",
        text : "Administracion Portal",
    },
    {
        component : views.AdmPortalFiles,
        path : "/adm-portal_files/:idPortal?",
        text : "Administracion Portal",
    }
]

export const routesWithPerms = {
    tires : {
        component : views.Tires,
        path : "/neumaticos/:width?/:profile?/:rim?",
    },
    broker50 : {
        component : views.Broker50,
        path : "/broker50/:width?/:profile?/:rim?",
    },
    broker300 : {
        component : views.Broker300,
        path : "/broker300/:width?/:profile?/:rim?",
    },
    tiresOpportunity : {
        component : views.TiresOpportunity,
        path : "/oportunidad/:width?/:profile?/:rim?",
    },
    tire : {
        component : views.TireDetail,
        path : "/neumatico/:id",
    },
    batteries : {
        component : views.Battery,
        path : "/baterias/:batteryType?",
    },
    battery : {
        component : views.BatteryDetail,
        path : "/bateria/:family/:subFamily/:id",
    },
    consumables : {
        component : views.Consumables,
        path : "/consumibles",
    },
    consumable : {
        component : views.ConsumableDetail,
        path : "/consumible/:family/:id",
    },
    lubricants : {
        component : views.Lubricants,
        path : "/lubricantes/:productType?",
    },
    lubricant : {
        component : views.LubricantDetail,
        path : "/lubricante/:family/:id",
    },
    chains : {
        component : views.Chains,
        path : "/cadenas",
    },
    chain : {
        component : views.ChainDetail,
        path : "/cadena/:family/:id",
    },
    brushes : {
        component : views.Brushes,
        path : "/escobillas",
    },
    brush : {
        component : views.BrushDetail,
        path : "/escobilla/:family/:id",
    },
}

export const sideButtons = {
    tires : {
        path : "/neumaticos",
        topText : "Neumáticos",
        text : "Todos",
        cssClass : "tire-icon",
    },
    broker50 : {
        path : "/broker50",
        topText : "Neumáticos",
        text : "Broker 50/14",
        cssClass : "tire-icon",
    },
    broker300 : {
        path : "/broker300",
        topText : "Neumáticos",
        text : "Broker 300/44",
        cssClass : "tire-icon",
    },
    tiresOpportunity : {
        path : "/oportunidad",
        topText : "Neumáticos",
        text : "Oportunidad",
        cssClass : "tire-icon",
    },
    batteries : {
        path : "/baterias",
        text : "Baterias",
        cssClass : "battery-icon",
    },
    consumables : {
        path : "/consumibles",
        text : "Consumibles",
        cssClass : "consumables-icon",
    },
    lubricants : {
        path : "/lubricantes",
        text : "Lubricantes",
        cssClass : "lubricants-icon",
    },
    chains : {
        path : "/cadenas",
        text : "Cadenas",
        cssClass : "chains-icon",
    },
    brushes : {
        path : "/escobillas",
        text : "Escobillas",
        cssClass : "brushes-icon",
    }
}

export const sideTopButtons = {
    home : {
        path : "/neumaticos",
        text : "Inicio",
        cssClass : "home-icon",
    },
    orders : {
        path : "/pedidos",
        text : "Pedidos y Anulaciones",
        cssClass : "orders-icon",
    },
    devolutions : {
        path : "/devoluciones",
        text : "Devoluciones",
        cssClass : "devolutions-icon",
    },
    invoice : {
        path : "/facturas",
        text : "Albaranes y Facturas",
        cssClass : "invoice-icon",
    },
    budget : {
        path : "/presupuestos",
        text : "Presupuestos",
        cssClass : "budget-icon",
    },
    setting : {
        path : "/adm-configuracion",
        text : "Configuración",
        cssClass : "setting-icon",
    },
    admUsers : {
        path : "/adm-usuarios",
        text : "Adm Usuarios",
        cssClass : "admUsers-icon",
    },
    admIps : {
        path : "/adm-ips",
        text : "Adm IPs",
        cssClass : "admIps-icon",
    },
    admSearch : {
        path : "/adm-busquedas",
        text : "Adm Busquedas ",
        cssClass : "admSearch-icon",
    },
    admOrders : {
        path : "/adm-pedidos",
        text : "Adm Pedidos",
        cssClass : "admOrders-icon",
    },
    admPortal : {
        path : "/adm-portal",
        text : "Adm Portal",
        cssClass : "admPortal-icon",
    },
}


export default routesNoLogin
