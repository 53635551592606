import React, {useContext} from 'react';

import LogoTop from 'components/Template/LogoTop/LogoTop'
import MenuTop from 'components/Menu/MenuTop/MenuTop'
import MenuBurgerTop from 'components/Menu/MenuBurgerTop/MenuBurgerTop'

import ThemeContext from 'context/ThemeContext'
import Context from 'context/UserContext'

import "./Header.css"
import StarsDiscounts from '../StarsDiscounts/StarsDiscounts';

export default function Header() {
    const { mobile } = useContext(ThemeContext)
    const { isLogged } = useContext(Context)

    return (
        (!mobile) ?
        <div className={isLogged() && "ics-container-header"}>
            <div className="ics-header ics-app">
                {
                    isLogged() &&
                        <>
                            <LogoTop />
                            <MenuTop />
                            <StarsDiscounts />
                        </>
                }
            </div>
        </div>
        :
        <div className={isLogged() && "ics-container-header"}>
            <div className="ics-header ics-app">
                {
                    isLogged() &&
                        <>
                            <LogoTop />
                            <MenuBurgerTop />
                            <StarsDiscounts />
                        </>
                }
            </div>
        </div>
    )
}
