import React from 'react'
import starOK from './images/star_a_OK.png'
// import starA from './images/star_a.png'
import starN from './images/star_n.png'


export default function StarsDiscounts() {
    return (

        <div className="starDiscount">
            <p>
                <span className="starDay">L</span>
                <span className="starDay">M</span>
                <span className="starDay">X</span>
                <span className="starDay">J</span>
            </p>
            <img className="starImg" src={starOK} alt="Resultados"></img>
            <img className="starImg" src={starN} alt="Resultados"></img>
            <img className="starImg" src={starN} alt="Resultados"></img>
            <img className="starImg" src={starN} alt="Resultados"></img>
            <img className="starImgB" src={starN} alt="Resultados"></img>
            <br></br>
            <small> Acumulado 0,2% de descuento
                para tus compras del viernes </small><br></br>
            <small className="fidelizacion_info">(+ información)</small>
        </div>
    )
}
