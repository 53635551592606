export const Consumables = (state = {
    isLoading: false,
    consumables: [],
    errorMessage: ''
}, action) => {
    switch(action.type) {
        case 'REQUEST_CONSUMABLES':
            state.isLoading = true;
            state.prevSearch = action.search
                return {...state};
        case 'SET_CONSUMABLES':
            state.isLoading = false
            state.consumables = action.consumables
            state.errorMessage = action.errorMessage

            return {...state};
        default:
            return state;
        }
    }

    export default Consumables;
