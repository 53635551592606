import React, { useContext } from 'react';

import "./Footer.css"
import Context from 'context/UserContext';
import { Link } from 'wouter';

function Footer() {
    const { isLogged } = useContext(Context)
    const date = new Date()

    return (
        <div className="ics-footer ics-app">
            <div>
                <strong>Teléfono de atención al cliente:</strong> (0034) 96 379 04 03
            </div>
            {
                (isLogged()) && <div>
                    <Link to="/condiciones-venta">Condiciones generales de venta</Link>
                </div>
            }
            <div>
                <strong>©{date.getFullYear()} Toprecambios</strong>
            </div>
        </div>
    )
}

export default React.memo(Footer)
