/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect } from 'react'
//import { useLocation } from 'wouter'

import Context from 'context/RoutesContext'
import UserContext from 'context/UserContext'
import routesNoLogin, { routesLogin, routesAdmin, routesWithPerms, sideButtons as sideButtonsConf, sideTopButtons as sideTopButtonsConf } from 'config/routing/Routes'

export default function useRoutes() {
    const {
        routes, setRoutes,
        sideButtons, setSideButtons,
        topButtons, setTopButton,
        sideTopButtons, setSideTopButtons,
    } = useContext(Context)
    const { isLogged, getPermissionValue, isAdmin } = useContext(UserContext)

    /**
     * Extrae la ruta pasada
     */
    const getRouteWithPerms = (key) => {
        let { [key]: route } = routesWithPerms
        return route
    }
    /**
     * Extre el boton del side bar pasado
     */
    const getSideButtonConf = (key) => {
        let { [key]: button } = sideButtonsConf
        return button
    }

    /**
     * Extre el boton del side bar pasado
     */
    const getSideTopButtonConf = (key) => {
        let { [key]: button } = sideTopButtonsConf
        return button
    }

    /**
     * Funcion que carga las rutas una vez ralizado login
     */
    const initLoginRoutes = useCallback(() => {
        let sideButtons = []
        let sideTopButtons = []
        let routes = [...routesNoLogin]

        if (getPermissionValue("buscar_broker50") === "1") {
            routes = [...routes, getRouteWithPerms('broker50')]
            sideButtons = [...sideButtons, getSideButtonConf('broker50')]
        }
        if (getPermissionValue("buscar_broker300") === "1") {
            routes = [...routes, getRouteWithPerms('broker300')]
            sideButtons = [...sideButtons, getSideButtonConf('broker300')]
        }
        if (getPermissionValue("no_buscar_neumaticos") !== "1") {
            routes = [...routes, getRouteWithPerms('tiresOpportunity'), getRouteWithPerms('tires')]
            sideButtons = [getSideButtonConf('tires'), ...sideButtons,  getSideButtonConf('tiresOpportunity')]
        }

        if (getPermissionValue("buscar_broker50") === "1" || getPermissionValue("buscar_broker300") === "1" || getPermissionValue("no_buscar_neumaticos") !== "1") {
            routes = [...routes, getRouteWithPerms('tire')]
        }

        if (getPermissionValue("no_buscar_baterias") !== "1") {
            routes = [...routes, getRouteWithPerms('batteries'), getRouteWithPerms('battery')]
            sideButtons = [...sideButtons, getSideButtonConf('batteries')]
        }
        if (getPermissionValue("no_buscar_consumibles") !== "1") {
            routes = [...routes, getRouteWithPerms('consumables'), getRouteWithPerms('consumable')]
            sideButtons = [...sideButtons, getSideButtonConf('consumables')]
        }
        if (getPermissionValue("no_buscar_lubricantes") !== "1") {
            routes = [...routes, getRouteWithPerms('lubricants'), getRouteWithPerms('lubricant')]
            sideButtons = [...sideButtons, getSideButtonConf('lubricants')]
        }
        if (getPermissionValue("no_buscar_cadenas") !== "1") {
            routes = [...routes, getRouteWithPerms('chains'), getRouteWithPerms('chain')]
            sideButtons = [...sideButtons, getSideButtonConf('chains')]
        }
        if (getPermissionValue("no_buscar_escobillas") !== "1") {
            routes = [...routes, getRouteWithPerms('brushes'), getRouteWithPerms('brush')]
            sideButtons = [...sideButtons, getSideButtonConf('brushes')]
        }
        // routes = [...routes, ...sideTopButtons]


        sideTopButtons = [...sideTopButtons, getSideTopButtonConf('home')]
        if (isAdmin()) {
            sideTopButtons = [...sideTopButtons, getSideTopButtonConf('setting')]
            sideTopButtons = [...sideTopButtons, getSideTopButtonConf('admUsers')]
            sideTopButtons = [...sideTopButtons, getSideTopButtonConf('admIps')]
            // sideTopButtons = [...sideTopButtons, getSideTopButtonConf('admSearch')]
            // sideTopButtons = [...sideTopButtons, getSideTopButtonConf('admOrders')]
            sideTopButtons = [...sideTopButtons, getSideTopButtonConf('admPortal')]

            routes = [...routes, ...routesAdmin]
        } else {
            sideTopButtons = [...sideTopButtons, getSideTopButtonConf('orders')]
            sideTopButtons = [...sideTopButtons, getSideTopButtonConf('devolutions')]
            sideTopButtons = [...sideTopButtons, getSideTopButtonConf('invoice')]
            sideTopButtons = [...sideTopButtons, getSideTopButtonConf('budget')]
        }
        routes = [...routes, ...routesLogin]


        setRoutes(routes)
        setSideButtons(sideButtons)
        setSideTopButtons(sideTopButtons)
        setTopButton(routesLogin)
    }, [getPermissionValue, setRoutes, setSideButtons, setSideTopButtons, setTopButton])


    /**
     * Funcion para inicializar las rutas cuando no se ha realizado el login
     */
    const initNoLoginRoutes = useCallback(() => {
        setRoutes(routesNoLogin)
    }, [setRoutes])

    /**
     * Funcion que devuelve las rutas
     */
    const getRoutes = () => {
        return routes
    }
    /**
     * Funcion que devuelve los botones de la barra superior
     */
    const getTopButtons = () => {
        return topButtons
    }
    /**
     * Funcion que devuelve los botones de la barra lateral
     */
    const getSideButtons = () => {
        return sideButtons
    }
    /**
     * Funcion que devuelve los botones de la barra top
     */
    const getSideTopButtons = () => {
        return sideTopButtons
    }

    useEffect(() => {
        if (isLogged()) {
            initLoginRoutes()
        } else {
            initNoLoginRoutes()
        }
    }, [isLogged, initLoginRoutes, initNoLoginRoutes])


    return {
        getRoutes,
        getTopButtons,
        getSideButtons,
        getSideTopButtons
    }
}


export const generateProductRoute = (product) => {
    let route
    switch(product.product.productType.id) {
        case 1:
            route = "/neumatico/" + product.idProduct
            break
        case 2:
            route = "/"
            //route = "/bateria/" + encodeURI(product.product.family.name) + "/" + encodeURI(product.product.subFamily.name) + "/" + product.idProduct
            break
        default:
        route = "/"
    }

    return route
}
