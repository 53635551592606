import { lazy } from "react";

const Tires = lazy(() => import("./Tires/Tires"))
const Broker50 = lazy(() => import("./Broker50/Broker50"))
const Broker300 = lazy(() => import("./Broker300/Broker300"))
const TiresOpportunity = lazy(() => import("./TiresOpportunity/TiresOpportunity"))
const TireDetail = lazy(() => import("./Tires/TireDetail"))
const Battery = lazy(() => import("./Battery/Battery"))
const BatteryDetail = lazy(() => import("./Battery/BatteryDetail"))
const Consumables = lazy(() => import("./Consumables/Consumables"))
const ConsumableDetail = lazy(() => import("./Consumables/ConsumableDetail"))
const Lubricants = lazy(() => import("./Lubricants/Lubricants"))
const LubricantDetail = lazy(() => import("./Lubricants/LubricantDetail"))
const Chains = lazy(() => import("./Chains/Chains"))
const ChainDetail = lazy(() => import("./Chains/ChainDetail"))
const Brushes = lazy(() => import("./Brushes/Brushes"))
const BrushDetail = lazy(() => import("./Brushes/BrushDetail"))
const Cart = lazy(() => import("./Cart/Cart"))
const Login = lazy(() => import("./Login/Login"))
const Registry = lazy(() => import("./Registry/Registry"))
const NotFound = lazy(() => import("./NotFound/NotFound"))
const Invoice = lazy(() => import("./Invoice/Invoice"))
const Orders = lazy(() => import("./Orders/Orders"))
const OrdersDetail = lazy(() => import("./Orders/OrdersDetail"))
const Budget = lazy(() => import("./Budget/Budget"))
const BudgetEdit = lazy(() => import("./Budget/BudgetEdit"))
const Documents = lazy(() => import("./Documents/Documents"))
const Devolutions = lazy(() => import("./Devolutions/Devolutions"))
const Warranty = lazy(() => import("./Warranty/Warranty"))
const Transparency = lazy(() => import("./Transparency/Transparency"))
const Info = lazy(() => import("./Info/Info"))
const SellConditions = lazy(() => import("./SellConditions/SellConditions"))
const ProductDetails = lazy(() => import("./ProductDetails/ProductDetails"))
const AdmSetting = lazy(() => import("./AdmSetting/AdmSetting"))
const AdmSearch = lazy(() => import("./AdmSearch/AdmSearch"))
const AdmUsers = lazy(() => import("./AdmUsers/AdmUsers"))
const AdmUsersCreate = lazy(() => import("./AdmUsers/AdmUsersCreate"))
const AdmUsersEdit = lazy(() => import("./AdmUsers/AdmUsersEdit"))
const UserDetail = lazy(() => import("./Users/UserDetail"))
const AdmIps = lazy(() => import("./AdmIps/AdmIps"))
const AdmIpsDetail = lazy(() => import("./AdmIps/AdmIpsDetail"))
const AdmOrders = lazy(() => import("./AdmOrders/AdmOrders"))
const AdmPortal = lazy(() => import("./AdmPortal/AdmPortal"))
const AdmPortalFiles = lazy(() => import("./AdmPortal/AdmPortalFiles/AdmPortalFiles"))

export default {
    Tires,
    Broker50,
    Broker300,
    TiresOpportunity,
    TireDetail,
    Battery,
    BatteryDetail,
    Consumables,
    ConsumableDetail,
    Lubricants,
    LubricantDetail,
    Chains,
    ChainDetail,
    Brushes,
    BrushDetail,
    Cart,
    Login,
    Registry,
    NotFound,
    Invoice,
    Orders,
    OrdersDetail,
    Devolutions,
    Warranty,
    Info,
    SellConditions,
    Documents,
    Budget,
    BudgetEdit,
    Transparency,
    ProductDetails,
    AdmIps,
    AdmIpsDetail,
    AdmSearch,
    AdmUsers,
    AdmUsersCreate,
    AdmUsersEdit,
    UserDetail,
    AdmSetting,
    AdmOrders,
    AdmPortal,
    AdmPortalFiles
};
