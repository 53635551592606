import React, { useContext } from 'react'

import MenuItem from "components/Menu/MenuItem";

import useRoutes from 'hooks/useRoutes'

import Context from 'context/ThemeContext'

import "./SideTop.css"

export default function SideTop() {
    //const { mobile } = useContext(Context)
    const mobile = false;
    const { getSideTopButtons } = useRoutes();

    return (
        (!mobile) ?
            <div className="ics-page-sidetop">
                {
                    getSideTopButtons().map(({ path, text, cssClass }) => {
                            return <MenuItem
                                key={path}
                                path={path}
                                text={text}
                                cssClass={cssClass} />
                    })
                }
            </div>
            : <div className="ics-page-sidetop">
                {
                    getSideTopButtons().map(({ path, text, cssClass }) => {
                            return <MenuItem
                                key={path}
                                path={path}
                                text={text}
                                cssClass={cssClass} />
                    })
                }
            </div>

    )
}
