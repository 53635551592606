import React, {useCallback} from 'react'
import { useLocation as wouterLocation } from "wouter";
import {BASE_URL} from 'config/defines'

export function icsUseLocation() {

    const [location, setLocation] = wouterLocation();
    return [location, setLocation, window.location.search];
}

export function formatUrl(route, searchParams) {
    const url = new URL(BASE_URL + route);
    url.search = new URLSearchParams(searchParams);

    return url;
}

export function returnRouterParams(query) {
    const urlSearchParams = new URLSearchParams(query);
    return Object.fromEntries(urlSearchParams);
}
