import React, { useContext } from 'react'

import MenuItem from "components/Menu/MenuItem";

import useRoutes from 'hooks/useRoutes'

import Context from 'context/ThemeContext'

import "./SideBar.css"

export default function SideBar() {
    const { mobile } = useContext(Context)
    const { getSideButtons } = useRoutes();

    return (
        (!mobile) ?
            <div className="ics-page-sidebar">
                {
                    getSideButtons().map(({ path, topText, text, cssClass }) => {
                            return <MenuItem
                                key={path}
                                path={path}
                                topText={topText}
                                text={text}
                                cssClass={cssClass} />
                    })
                }
            </div>
            : <div className="ics-page-topbar">
                {
                    getSideButtons().map(({ path, topText, text, cssClass }) => {
                            return <MenuItem
                                key={path}
                                path={path}
                                topText={topText}
                                text={text}
                                cssClass={cssClass} />
                    })
               }
            </div>
     )
}
