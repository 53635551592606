import React from 'react'

import MenuItem from "components/Menu/MenuItem";
import Logout from "components/Menu/Logout/Logout"
import useRoutes from 'hooks/useRoutes'

import './MenuTop.css'

export default function MenuTop() {
    const { getTopButtons } = useRoutes();
    return (
        <div className="ics-menu-buttons">
            <Logout />
            {
                getTopButtons().map(({ path, text, cssClass }) => (
                    <MenuItem
                        key={path}
                        path={path}
                        cssClass={cssClass}
                        title={text}
                        />
                ))
            }

        </div>
    )
}
