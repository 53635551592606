import React, { useContext, useEffect } from 'react';

import Header from 'components/Template/Header/Header'
import Footer from 'components/Template/Footer/Footer'
import ContentPage from 'components/Template/ContentPage/ContentPage'

import Context from 'context/UserContext'
import ThemeContext from 'context/ThemeContext'

import './App.css';
import { useLocation } from 'wouter';
import Loader from 'components/Template/Loader/Loader';
import { Col1, Row } from 'components/ElementsStyled/Containers';

function App() {
    const { login, doLogin, reload, doLogout, getPermissionValue, isLogged } = useContext(Context)
    const { mobile } = useContext(ThemeContext)

    let [location, setLocation] = useLocation()
    useEffect(() => {
        if (login.state === "idle" && login.jwt !== null) {
            reload()
        }
    })

    if (login.state === "unlogged" && location !== "/login") {
        //Si estamos logueados y estamos en el login enviamos al escritorio
        setLocation("/login")
    }

    if (login.state === "idle") {
        if (login.jwt === null && location !== "/login") {
            //Si no hay jwt y no estamos en login, enviamos a login
            setLocation("/login")
        }
        if (login.jwt !== null) {
            //Si hay jwt, ponemos el cargador mienras se obtinen los datos
            return <Row>
                <Col1 style={{
                    marginTop: "15%"
                }}>
                    <Loader />
                </Col1>
            </Row>
        }
    }
    if (isLogged() && location === "/login") {
        //Si estamos logueados y estamos en el login enviamos al escritorio
        setLocation("/neumaticos")
        /* if (getPermissionValue("no_buscar_neumaticos") !== "1") {
            setLocation("/neumaticos")
            return;
        } else if (getPermissionValue("buscar_broker50") === "1") {
            setLocation("/broker50")
            return;
        } else if (getPermissionValue("buscar_broker300") === "1") {
            setLocation("/broker300")
            return;
        } else if (getPermissionValue("no_buscar_baterias") !== "1") {
            setLocation("/baterias")
            return;
        } else if (getPermissionValue("no_buscar_consumibles") !== "1") {
            setLocation("/consumibles")
            return;
        } else if (getPermissionValue("no_buscar_lubricantes") !== "1") {
            setLocation("/lubricantes")
            return;
        } else if (getPermissionValue("no_buscar_cadenas") !== "1") {
            setLocation("/cadenas")
            return;
        } else if (getPermissionValue("no_buscar_escobillas") !== "1") {
            setLocation("/escobillas")
            return;
        } else {
            setLocation("/user")
            return;
        } */
    }

    if (mobile) {
        document.getElementById("root").classList.add("mobile-root-container")
    } else {
        document.getElementById("root").classList.remove("mobile-root-container")
    }

    return (
            (login !== undefined && isLogged()) ? <>
                <Header />
                <ContentPage />
                <Footer />
            </>
            :
            <ContentPage />
    );
}

export default App;
