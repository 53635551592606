import React, { useCallback, useState } from 'react'
import { getUserData } from 'services/login'
import loginService from 'services/login'
import {arraySearchPrimaryAddress} from 'hooks/useUser'

const Context = React.createContext({});

export function UserContextProvider({ children }) {
    const [login, setLogin] = useState({ state: 'idle', error: null, userData: null, userId: null, idAddress: null, jwt: window.sessionStorage.getItem('jwt') })

    /**
     * Metodo que ejecuta una llamada servicio de login enviando usuario y contraseña
     * Retorna un jwt y un item con los datos necesarios del usuario
     */
    const doLogin = ({ username, password }) => {
        return loginService({ username, password })
            .then(result => {
                if (result.error) {
                    throw new Error(result.error)
                }
                const { jwt, item } = result
                const address = arraySearchPrimaryAddress(item.addresses)
                window.sessionStorage.setItem('jwt', jwt)
                window.sessionStorage.setItem('id', item.id)
                window.sessionStorage.setItem('idAddress', address.id)
                setLogin({
                    ...login,
                    state: 'logged',
                    userData: item,
                    userId: item.id,
                    idAddress: address.id,
                    error: null,
                    jwt: jwt
                })
                return true
            }).catch(error => {
                window.sessionStorage.removeItem('jwt')
                window.sessionStorage.removeItem('id')
                window.sessionStorage.removeItem('idAddress')
                setLogin({
                    ...login,
                    state: 'unlogged',
                    userData: null,
                    userId: null,
                    idAddress: null,
                    error: error,
                    jwt: null
                })
                throw error
            })
    }

    const reload = () => {
        return getUserData({userId: window.sessionStorage.id, jwt: window.sessionStorage.jwt})
            .then(item => {
                if (item.error) {
                    throw new Error(item.error)
                }
                setLogin({
                    ...login,
                    state: 'logged',
                    userData: item,
                    userId: item.id,
                    idAddress: window.sessionStorage.idAddress,
                    error: null,
                    jwt: window.sessionStorage.jwt
                })
                return true
            }).catch(error => {
                window.sessionStorage.removeItem('jwt')
                window.sessionStorage.removeItem('id')
                window.sessionStorage.removeItem('idAddress')
                setLogin({
                    ...login,
                    state: 'unlogged',
                    userData: null,
                    userId: null,
                    idAddress: null,
                    error: error,
                    jwt: null
                })
                throw error
            })
    }

    const doLogout = () => {
        window.sessionStorage.removeItem('jwt')
        window.sessionStorage.removeItem('id')
        window.sessionStorage.removeItem('idAddress')
        return setLogin({
            ...login,
            state: 'unlogged',
            userData: null,
            userId: null,
            idAddress: null,
            error: null,
            jwt: null
        })
    }
    /**
     * Metodo que recibe un texto de un permiso y devuelve su valor o false si no lo tiene
     */
    const getPermissionValue = useCallback((permission) => {
        if (!isLogged()) {
            return null
        }
        const { permissions } = login.userData

        if (permissions.hasOwnProperty(permission)) {
            return permissions[permission].value
        }
        return null
    }, [login])

    const isLogged = () => {
        return login.state === "logged"
    }

    const isAdmin = () => {
        return isLogged() && login.userData.idUserCategory === 1
    }

    const isForeign = () => {
        const address = arraySearchPrimaryAddress(login.userData.addresses)
        const idCountry = address.town.state.idCountry
        return (idCountry !== 1 && idCountry !== 2 && idCountry !== 3)
    }

    return <Context.Provider value={{
        login, doLogin, reload, doLogout, isLogged, isAdmin, isForeign, getPermissionValue
    }}>
        {children}
    </Context.Provider>
}

export default Context;
