import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "wouter"

export default function MenuItem({ path, topText, text, cssClass, title }) {
    const [active, setActive] = useState("")
    const [location,] = useLocation()

    useEffect(() => {
        const route = location.split("/").find((route, index) => {
            return index === 1
        })
        if ("/" + route === path) {
            setActive("active")
        } else {
            setActive("")
        }
    }, [location, path, setActive])

    return (

        <Link
        to={path}>
            <div className={"ics-btn-menu ics-btn-side " + active} title={title}>
                <span className={cssClass}></span>
                <div className={"text-menu-side"}>
                {
                    topText && <span className="topText">{topText}</span>
                }
                    <span className={text}>{text}</span>
                </div>
            </div>
        </Link>
    )
}
