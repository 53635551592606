export const IS_DEV = true;
export const IS_LOCAL = false;

export const BASE_URL = (!IS_DEV) ? 'https://toprecambios.com' : ((IS_LOCAL) ? 'http://localhost:3000' : 'http://nuevaTopWeb.desarrollogrupoics.com');
//export const BASE_URL = (IS_DEV) ? 'http://nuevaTopWeb.desarrollogrupoics.com' : 'https://toprecambios.com';
export const WEB_URL = BASE_URL + ((!IS_DEV) ? '/profesional' : '/web');
export const PUBLIC_URL = 'https://toprecambios.com/web';
//export const IMAGES_URL = 'https://nuevatopmedia.desarrollogrupoics.com/web';
export const IMAGES_URL = (IS_LOCAL) ? 'https://new_top_media.santi.com' : 'https://nuevatopmedia.desarrollogrupoics.com';
//export const IMAGES_URL = 'https://toprecambios.desarrollogrupoics.com/web/img';
export const UPLOADS_URL = BASE_URL + '/Uploads';

export const API_URI = BASE_URL + "/api/api.php";

export const BATTERY_TYPES = {
    1: "Coche/Camión",
    2: "Moto",
    3: "Náutica"
}
