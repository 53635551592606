import React, {useState} from 'react'

// import MenuItem from "components/Menu/MenuItem";
// import Logout from "components/Menu/Logout/Logout"
import useRoutes from 'hooks/useRoutes'

import './MenuBurgerTop.css'
import { Link } from 'wouter';

export default function MenuBurgerTop() {
    const { getTopButtons } = useRoutes();

    const [showMenu, setShowMenu] = useState(false)
    const onChangeShowMenu = () => {
        setShowMenu(!showMenu)
    }
    return (
        <div className="ics-menu-burger-container">
            <div className="ics-menu-burger" onClick={onChangeShowMenu}>
                <img alt="menu" src={showMenu ? "/images/icons/menu-top/burger-close.png" : "/images/icons/menu-top/burger-menu.png"} />
            </div>
            <div className={"ics-menu-items" + (showMenu ? " show" : "")}>
                <ul>
                {
                    getTopButtons().map(({ path, text, cssClass }) => (
                        (text) &&
                        <li key={path} className="ics-menu-burger-item">
                            <Link to={path}>{text}</Link>
                        </li>
                    ))
                }
                </ul>
            </div>
        </div>
    )
}
