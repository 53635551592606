import React, { useState } from 'react'
import Media from 'react-media'

const Context = React.createContext({})
const mobileBreakPoint = 700

export function ThemeContextProvider({ children }) {
    const [, setMobile] = useState(false)

    return (
    <Media query={{ maxWidth: mobileBreakPoint }}>
        {
            mobile => mobile ?
            <Context.Provider value={{ mobile, setMobile }}>
                {children}
            </Context.Provider>
            :
            <Context.Provider value={{ mobile, setMobile }}>
                {children}
            </Context.Provider>
        }
    </Media>
    )
}

export default Context
