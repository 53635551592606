
import React from 'react'
import { Link } from 'wouter'
import { IMAGES_URL } from 'config/defines'

export default function MenuTop() {
    return (
        <Link to="/">
            <div className="link">
                <img alt="Logo Top Recambios" src={`${IMAGES_URL}/web/logo.png`} />
            </div>
        </Link>
    )
}
