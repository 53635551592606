import React from "react";

import { Switch, Route } from "wouter"

import { TiresContextProvider } from 'context/TiresContext'

export default function AppRoute({ routes }) {

    return (
        <div>
            <TiresContextProvider>
                <Switch>
                    {
                        routes.map(({ path, component }) => (
                            <Route
                                key={path}
                                path={path}
                                component={component}
                            />
                        ))
                    }
                </Switch>
            </TiresContextProvider>
        </div>
    );
}
