import React, { useState } from 'react'

const Context = React.createContext({})

export function TiresContextProvider({ children }) {
    const [tires, setTires] = useState([])

    return <Context.Provider value={{ tires, setTires }}>
        {children}
    </Context.Provider>
}

export default Context