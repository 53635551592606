import sendRequest, { API_KEY, API_URI } from 'services/settings'

export default function userConfig({ jwt, username, password }) {
    const myHeaders = new Headers();
    //myHeaders.append("autorization")
    myHeaders.append("ICS-API-KEY", API_KEY);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({ username, password });

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
    };

    return fetch(`${API_URI}/login`, requestOptions)
        .then(res => {
            if (!res.ok) throw new Error("Response is NOT ok")
                return res.json()
        }).then(result => {
            const { jwt } = result
            return jwt
        })
}

export function getAdminList({ jwt, userId, username = "", page = 1}) {
    const headers = new Headers();
    headers.append("Authorization", "Bearer " + jwt);

    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    return sendRequest(`/${userId}/admin/users${(username && username !== "" ? "/find/" + username : "")}?page=${page}`, requestOptions)
    .then(result => {
        return result.item
    }).catch(err => {
        console.error(err)
    })
}

export function getAdminUserDetail({ jwt, userId, id}) {
    const headers = new Headers();
    headers.append("Authorization", "Bearer " + jwt);

    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    return sendRequest(`/${userId}/admin/users/${id}`, requestOptions)
    .then(result => {
        let perms = []
        Object.values(result.item.permissions).forEach(element => {
            perms[element.reference] = element.value
        });
        result.item.permissions = perms

        return result.item
    }).catch(err => {
        console.error(err)
    })
}

export function postUserData({ jwt, userId, data }) {
    const headers = new Headers();
    headers.append("Authorization", "Bearer " + jwt);

    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    };

    return sendRequest(`/${userId}/admin/users`, requestOptions)
    .then(result => {
        return result.item
    }).catch(result => {
        console.error(result)
        return []
    })
}

export function putUserData({ jwt, userId, id, data }) {
    const headers = new Headers();
    headers.append("Authorization", "Bearer " + jwt);

    const requestOptions = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(data)
    };

    return sendRequest(`/${userId}/admin/users/${id}`, requestOptions)
    .then(result => {
        return result.item
    }).catch(result => {
        console.error(result)
        return []
    })
}

export function putUserPermission({ jwt, userId, id, permissions }) {
    const headers = new Headers();
    headers.append("Authorization", "Bearer " + jwt);

    console.log(permissions)
    const body = {
        idUser: id,
        permissions: permissions
    }

    const requestOptions = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(body)
    };

    return sendRequest(`/${userId}/admin/users/permissions`, requestOptions)
    .then(result => {
        return result.item
    }).catch(result => {
        console.error(result)
        return []
    })
}
