import React, { useState } from 'react'

const Context = React.createContext({});

export function RoutesContextProvider({ children }) {
    const [routes, setRoutes] = useState([])
    const [sideButtons, setSideButtons] = useState([])
    const [topButtons, setTopButton] = useState([])
    const [sideTopButtons, setSideTopButtons] = useState([])

    return <Context.Provider value={{
        routes,
        sideButtons,
        topButtons,
        sideTopButtons,
        setRoutes,
        setSideButtons,
        setTopButton,
        setSideTopButtons,
    }}>
        {children}
    </Context.Provider>
}

export default Context;