import React, { Suspense, useCallback, useContext, useEffect } from 'react';

import AppRoute from "components/AppRoute/AppRoute";
import SideBar from 'components/Menu/SideBar/SideBar'
import SideTop from 'components/Menu/SideTop/SideTop'

import { useLocation } from 'wouter'
import useRoutes from 'hooks/useRoutes'

import "./ContentPage.css"
import Context from 'context/UserContext';

export default function ContentPage() {
    const { login, isLogged, getPermissionValue } = useContext(Context)
    const [location, setLocation] = useLocation()
    const { getRoutes } = useRoutes();
    /**
     * Funcion que redirige a login
     */
    const goToLogin = useCallback(() => {
        setLocation('/login')
    }, [setLocation])
    /**
     * Funcion que carga la pagina principal segun los permisos del usuario
     */
    const goToHomePage = useCallback(() => {
        if (getPermissionValue("no_buscar_neumaticos") !== "1") {
            setLocation('/neumaticos')
            return;
        } else if (getPermissionValue("buscar_broker50") === "1") {
            setLocation('/broker50')
            return;
        } else if (getPermissionValue("buscar_broker300") === "1") {
            setLocation('/braker300')
            return;
        } else if (getPermissionValue("no_buscar_baterias") !== "1") {
            setLocation('/baterias')
            return;
        } else if (getPermissionValue("no_buscar_consumibles") !== "1") {
            setLocation('/consumibles')
            return;
        } else if (getPermissionValue("no_buscar_lubricantes") !== "1") {
            setLocation('/lubricantes')
            return;
        } else if (getPermissionValue("no_buscar_cadenas") !== "1") {
            setLocation('/cadenas')
            return;
        } else if (getPermissionValue("no_buscar_escobillas") !== "1") {
            setLocation('/escobillas')
            return;
        }
    }, [setLocation, getPermissionValue])

    useEffect(() => {
        if (!isLogged() && (location !== '/login' && location !== '/registro')) goToLogin()
        if (isLogged() && (location === "/" || location === "/login")) goToHomePage()
    }, [isLogged, location, goToLogin, goToHomePage])

    return (
        <div className="ics-page ics-app">

            <div className="ics-content">
                {
                    (isLogged()) && <SideTop />
                }

                {
                    (isLogged()) && <SideBar />
                }

                <div className="ics-page-content">
                    {
                        getRoutes() && <Suspense fallback={null}>
                            <AppRoute
                                routes={getRoutes()}

                            />
                        </Suspense>
                    }
                </div>
            </div>
        </div>
    )
}
